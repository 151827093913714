<template>
  <div class="DebtorManage">
    <div class="row row--justify-between row--align-center">
      <label class="fs-18 fw-med">
        Status:
        <span
          class="capitalize"
          data-cy="DebtorManagement_DebtorStatus"
        >
          {{ debtor.status }}
        </span>
      </label>
      <base-button
        @click="updateDebtor"
        class="bg-blue fc-white"
        data-cy="DebtorManagement_ActivateDebtor"
      >
        {{ debtor.status === 'active' ? 'De-Activate' : 'Activate' }}
      </base-button>
    </div>

    <hr class="Divider">

    <div class="row row--justify-between row--align-center">
      <label class="fs-18 fw-med">Merge</label>
      <base-button
        @click="showMerge = !showMerge"
        class="bg-blue fc-white"
      >
        {{ !showMerge ? 'Merge Debtor' : 'Close' }}
      </base-button>
    </div>
    <transition name="Transition__fade">
      <merge-debtor
        v-if="showMerge"
        @close-merge="showMerge = false"
        :debtor="debtor"
      />
    </transition>
  </div>
</template>

<script>
// Helpers
import { Debtor } from '../../../utils/api'
// Components
import BaseButton from '../../../components/base-button.vue'
import MergeDebtor from '../../../components/merge-debtor.vue'

export default {
  name: 'DebtorManage',

  components: {
    BaseButton,
    MergeDebtor,
  },

  data () {
    return {
      showMerge: false
    }
  },

  computed: {
    debtor () {
      return this.$store.getters.debtor
    }
  },

  methods: {
    async updateDebtor() {
      this.progressStart()

      try {
        const updatedDebtor = (await Debtor.update({
          id: this.debtor.id,
          status: this.debtor.status === 'active' ? 'inactive' : 'active'
        })).data;
        this.$store.commit('UPDATE_STORED_DEBTOR', updatedDebtor)
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Debtor Manage "Update Debtor"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError('Error deactivating debtor:', error)
        this.requestFailure('There was an issue updating the debtor')
      }
    },
  }
}
</script>

<style lang="sass">
.DebtorManage
  margin-top: rem(42px)
  padding: 0 rem(29px) 2.5rem

  .Divider
    margin: rem(42px) 0

</style>