var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "DebtorManage" },
    [
      _c(
        "div",
        { staticClass: "row row--justify-between row--align-center" },
        [
          _c("label", { staticClass: "fs-18 fw-med" }, [
            _vm._v("\n      Status:\n      "),
            _c(
              "span",
              {
                staticClass: "capitalize",
                attrs: { "data-cy": "DebtorManagement_DebtorStatus" }
              },
              [_vm._v("\n        " + _vm._s(_vm.debtor.status) + "\n      ")]
            )
          ]),
          _c(
            "base-button",
            {
              staticClass: "bg-blue fc-white",
              attrs: { "data-cy": "DebtorManagement_ActivateDebtor" },
              on: { click: _vm.updateDebtor }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.debtor.status === "active" ? "De-Activate" : "Activate"
                  ) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c("hr", { staticClass: "Divider" }),
      _c(
        "div",
        { staticClass: "row row--justify-between row--align-center" },
        [
          _c("label", { staticClass: "fs-18 fw-med" }, [_vm._v("Merge")]),
          _c(
            "base-button",
            {
              staticClass: "bg-blue fc-white",
              on: {
                click: function($event) {
                  _vm.showMerge = !_vm.showMerge
                }
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(!_vm.showMerge ? "Merge Debtor" : "Close") +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "Transition__fade" } },
        [
          _vm.showMerge
            ? _c("merge-debtor", {
                attrs: { debtor: _vm.debtor },
                on: {
                  "close-merge": function($event) {
                    _vm.showMerge = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }