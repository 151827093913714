<template>
  <div class="BaseRuleCreate">
    <div class="row row--justify-between">
      <label class="BaseRuleCreate__heading fs-18 fw-med mb-13">
        New Rule
      </label>
    </div>

    <!-- EXTERNAL VALIDATION -->
    <div
      v-if="validationInstructions"
      class="mb-12"
    >
      <label class="fc-red fs-12 uppercase">
        {{ validationInstructions }}
      </label>
    </div>


    <div class="column">
      <base-checkbox
        v-model="requireUser"
        class="mb-4"
        :label-for="'require-user'"
        :starting-value="requireUser"
      >
        Require Manual Approval?
      </base-checkbox>

      <base-checkbox
        v-model="matchDebtors"
        class="mb-16"
        :label-for="'match-debtors'"
        :starting-value="matchDebtors"
      >
        Match With Debtors in the System?
      </base-checkbox>

      <transition name="Transition__fade">
        <base-debtor-search
          v-if="matchDebtors"
          @selected="setDebtorID"
          class="mb-12"
          :debtor-i-d-from-query="debtorID"
          :label="true"
          :label-for="'Debtor'"
        >
          <template v-slot:label>
            DEBTOR
          </template>
        </base-debtor-search>
      </transition>

      <label class="fs-16 mb-14">Select Field(s) to make rule on</label>

      <v-select
        v-model="allowedFields.selected"
        aria-label="Select input to choose fields to make a rule on"
        class="BaseRuleCreate__fields-select mb-14"
        :clearable="false"
        :options="allowedFields.options"
        multiple
      />

      <transition name="Transition__fade">
        <div v-if="allowedFields.selected.length">
          <label class="fs-16 mb-14">Select Values for the fields to apply rule to</label>

          <div
            v-for="(field, index) in allowedFields.selected"
            :key="index"
            class="mb-10"
          >
            <div class="row row--align-center">
              <label class="fs-16">Field:&nbsp;</label>
              <label class="fs-16 italic">{{ field }}</label>
            </div>

            <base-checkbox
              v-model="allowedFields.match_all[field]"
              class="mb-8"
              :label-for="`match-all-${field}`"
              :starting-value="allowedFields.match_all[field]"
            >
              Match All?
            </base-checkbox>

            <transition name="Transition__fade">
              <base-input
                v-model.trim="allowedFields.values[field]"
                v-if="!allowedFields.match_all[field]"
                class="mb-14"
                :label="true"
                :label-for="`value-${field}`"
              >
                Value
              </base-input>
            </transition>
          </div>
        </div>
      </transition>

      <!-- BUTTONS -->
      <div class="row row--align-center mt-25">
        <base-button
          v-if="!editing"
          @click="createRule"
          @keydown.enter="createRule"
          class="bg-blue fc-white mr-6"
        >
          Create Rule
        </base-button>
        <base-button
          v-if="editing"
          @click="updateRule"
          @keydown.enter="updateRule"
          class="bg-blue fc-white mr-6"
        >
          Update Rule
        </base-button>
        <base-button
          @click="cancel"
          @keydown.enter="cancel"
          class="bg-light fc-white mr-6"
        >
          Cancel
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
// Helpers
import { UploadRule } from '../utils/api'
// Components
import BaseButton from './base-button.vue'
import BaseCheckbox from './base-checkbox.vue'
import BaseDebtorSearch from './base-debtor-search.vue'
import BaseInput from './base-input.vue'

const MATCH_SELF = '^___self___$'

export default {
  name: 'BaseRuleCreate',

  components: {
    BaseButton,
    BaseCheckbox,
    BaseDebtorSearch,
    BaseInput,
  },

  props: {
    rule: {
      type: Object,
      required: false,
      default: null,
    }
  },

  created () {
    if (this.rule) {
      this.editing = true
      // populate the fields
      this.debtorID = this.rule.match_id
      this.matchDebtors = this.rule.match_id != null
      this.requireUser = this.rule.require_user

      Object
        .keys(this.rule.match_regex)
        .forEach(key => {
          const value = this.rule.match_regex[key]
          this.allowedFields.selected.push(key)
          if (value === MATCH_SELF) this.allowedFields.match_all[key] = true
          else this.allowedFields.values[key] = value.slice(1, value.length - 1)
        })
    }
  },

  data () {
    return {
      allowedFields: {
        match_all: {},
        options: [
          'name',
          'address',
          'address_2',
          'city',
          'state',
          'zip',
          'mailing_address',
          'mailing_address_2',
          'mailing_city',
          'mailing_state',
          'mailing_zip',
          'phone',
          'mc',
          'dot',
          'insurance_company_name',
          'insurance_policy_number',
          'insurance_company_phone'
        ],
        regex_values: {},
        selected: [],
        valid: false,
        values: {}
      },
      debtorID: null,
      editing: false,
      matchDebtors: false,
      requireUser: false,
      selectedRules: '',
      validationInstructions: null
    }
  },

  methods: {
    cancel () {
      this.$emit('close-create-rule')
    },

    async createRule () {
      if (!this.validation()) return
      this.progressStart()
      this.generateRegexValues()

      try {
        // submit
        await UploadRule.create({
          match_id: this.matchDebtors ? this.debtorID : null,
          match_regex: this.allowedFields.regex_values,
          require_user: this.requireUser,
          type: 'debtor'
        })
        this.requestSuccess({ message: 'New rule successfully created' })
        this.$emit('fetch-rules')
      }
      catch (error) {
        this.captureSentryEvent(
          'Rule Create "Create" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue creating the rule' })
      }
    },

    generateRegexValues () {
      this.allowedFields.selected.forEach(field => {
        if (this.allowedFields.match_all[field]) {
          this.allowedFields.regex_values[field] = MATCH_SELF
        } else {
          this.allowedFields.regex_values[field] = `^${this.allowedFields.values[field]}$`
        }
      })
    },

    setDebtorID (filter) {
      if (!filter) {
        this.debtorID = null
        return
      }
      this.debtorID = filter.id
    },

    async updateRule () {
      if (!this.validation()) return
      this.progressStart()
      this.generateRegexValues()

      try {
        // submit
        await UploadRule.update({
          id: this.rule.id,
          match_id: this.matchDebtors ? this.debtorID : null,
          match_regex: this.allowedFields.regex_values,
          require_user: this.requireUser,
          type: 'debtor'
        })
        this.requestSuccess({ message: 'Rule successfully updated' })
        this.$emit('fetch-rules')
      }
      catch (error) {
        this.captureSentryEvent(
          'Rule Create "Update" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue creating the rule' })
      }
    },

    validation () {
      let valid = true
      this.validationInstructions = null

      // If the match in system is selected, debtorID must exist
      if (this.matchDebtors && !this.debtorID) {
        valid = false;
        this.validationInstructions = 'You must select a debtor if you select match with all debtors in the system'
      }

      // NEED only the regex. For each selected field there needs to be a value
      if (!this.allowedFields.selected.length) {
        valid = false
        this.validationInstructions = 'You must select at least one field to apply the rule to'
      }

      this.allowedFields.selected.forEach(field => {
        if (!this.allowedFields.values[field] && !this.allowedFields.match_all[field]) {
          valid = false
          this.validationInstructions = 'You must either select Match All or enter a value for each field'
        }
      })

      return valid
    },
  },
}
</script>

<style lang="sass">
.BaseRuleCreate

  &__fields-select
    min-width: rem(275px)
</style>