var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "BaseRuleCreate" }, [
    _vm._m(0),
    _vm.validationInstructions
      ? _c("div", { staticClass: "mb-12" }, [
          _c("label", { staticClass: "fc-red fs-12 uppercase" }, [
            _vm._v("\n      " + _vm._s(_vm.validationInstructions) + "\n    ")
          ])
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "column" },
      [
        _c(
          "base-checkbox",
          {
            staticClass: "mb-4",
            attrs: {
              "label-for": "require-user",
              "starting-value": _vm.requireUser
            },
            model: {
              value: _vm.requireUser,
              callback: function($$v) {
                _vm.requireUser = $$v
              },
              expression: "requireUser"
            }
          },
          [_vm._v("\n      Require Manual Approval?\n    ")]
        ),
        _c(
          "base-checkbox",
          {
            staticClass: "mb-16",
            attrs: {
              "label-for": "match-debtors",
              "starting-value": _vm.matchDebtors
            },
            model: {
              value: _vm.matchDebtors,
              callback: function($$v) {
                _vm.matchDebtors = $$v
              },
              expression: "matchDebtors"
            }
          },
          [_vm._v("\n      Match With Debtors in the System?\n    ")]
        ),
        _c(
          "transition",
          { attrs: { name: "Transition__fade" } },
          [
            _vm.matchDebtors
              ? _c("base-debtor-search", {
                  staticClass: "mb-12",
                  attrs: {
                    "debtor-i-d-from-query": _vm.debtorID,
                    label: true,
                    "label-for": "Debtor"
                  },
                  on: { selected: _vm.setDebtorID },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "label",
                        fn: function() {
                          return [_vm._v("\n          DEBTOR\n        ")]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    3347883505
                  )
                })
              : _vm._e()
          ],
          1
        ),
        _c("label", { staticClass: "fs-16 mb-14" }, [
          _vm._v("Select Field(s) to make rule on")
        ]),
        _c("v-select", {
          staticClass: "BaseRuleCreate__fields-select mb-14",
          attrs: {
            "aria-label": "Select input to choose fields to make a rule on",
            clearable: false,
            options: _vm.allowedFields.options,
            multiple: ""
          },
          model: {
            value: _vm.allowedFields.selected,
            callback: function($$v) {
              _vm.$set(_vm.allowedFields, "selected", $$v)
            },
            expression: "allowedFields.selected"
          }
        }),
        _c("transition", { attrs: { name: "Transition__fade" } }, [
          _vm.allowedFields.selected.length
            ? _c(
                "div",
                [
                  _c("label", { staticClass: "fs-16 mb-14" }, [
                    _vm._v("Select Values for the fields to apply rule to")
                  ]),
                  _vm._l(_vm.allowedFields.selected, function(field, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "mb-10" },
                      [
                        _c("div", { staticClass: "row row--align-center" }, [
                          _c("label", { staticClass: "fs-16" }, [
                            _vm._v("Field: ")
                          ]),
                          _c("label", { staticClass: "fs-16 italic" }, [
                            _vm._v(_vm._s(field))
                          ])
                        ]),
                        _c(
                          "base-checkbox",
                          {
                            staticClass: "mb-8",
                            attrs: {
                              "label-for": "match-all-" + field,
                              "starting-value":
                                _vm.allowedFields.match_all[field]
                            },
                            model: {
                              value: _vm.allowedFields.match_all[field],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.allowedFields.match_all,
                                  field,
                                  $$v
                                )
                              },
                              expression: "allowedFields.match_all[field]"
                            }
                          },
                          [_vm._v("\n            Match All?\n          ")]
                        ),
                        _c(
                          "transition",
                          { attrs: { name: "Transition__fade" } },
                          [
                            !_vm.allowedFields.match_all[field]
                              ? _c(
                                  "base-input",
                                  {
                                    staticClass: "mb-14",
                                    attrs: {
                                      label: true,
                                      "label-for": "value-" + field
                                    },
                                    model: {
                                      value: _vm.allowedFields.values[field],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.allowedFields.values,
                                          field,
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "allowedFields.values[field]"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              Value\n            "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            : _vm._e()
        ]),
        _c(
          "div",
          { staticClass: "row row--align-center mt-25" },
          [
            !_vm.editing
              ? _c(
                  "base-button",
                  {
                    staticClass: "bg-blue fc-white mr-6",
                    on: {
                      click: _vm.createRule,
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.createRule($event)
                      }
                    }
                  },
                  [_vm._v("\n        Create Rule\n      ")]
                )
              : _vm._e(),
            _vm.editing
              ? _c(
                  "base-button",
                  {
                    staticClass: "bg-blue fc-white mr-6",
                    on: {
                      click: _vm.updateRule,
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.updateRule($event)
                      }
                    }
                  },
                  [_vm._v("\n        Update Rule\n      ")]
                )
              : _vm._e(),
            _c(
              "base-button",
              {
                staticClass: "bg-light fc-white mr-6",
                on: {
                  click: _vm.cancel,
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.cancel($event)
                  }
                }
              },
              [_vm._v("\n        Cancel\n      ")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row row--justify-between" }, [
      _c(
        "label",
        { staticClass: "BaseRuleCreate__heading fs-18 fw-med mb-13" },
        [_vm._v("\n      New Rule\n    ")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }