var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "MergeDebtor" }, [
    _vm._m(0),
    _vm.validationInstructions
      ? _c("div", { staticClass: "mb-12" }, [
          _c("label", { staticClass: "fc-red fs-12 uppercase" }, [
            _vm._v("\n      " + _vm._s(_vm.validationInstructions) + "\n    ")
          ])
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "column" },
      [
        _c("base-debtor-search", {
          staticClass: "mb-14",
          attrs: {
            "debtor-i-d-from-query": _vm.debtorID,
            label: true,
            "label-for": "Debtor"
          },
          on: { selected: _vm.setDebtorID },
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function() {
                return [_vm._v("\n        DEBTOR\n      ")]
              },
              proxy: true
            }
          ])
        }),
        _c(
          "base-checkbox",
          {
            staticClass: "mb-2",
            attrs: { "label-for": "require-user", "starting-value": false },
            model: {
              value: _vm.makeRule,
              callback: function($$v) {
                _vm.makeRule = $$v
              },
              expression: "makeRule"
            }
          },
          [_vm._v("\n      Make Rule?\n    ")]
        ),
        _c(
          "transition",
          { attrs: { name: "Transition__fade" } },
          [
            _vm.makeRule
              ? _c("base-rule-create", {
                  on: {
                    "close-create-rule": function($event) {
                      _vm.makeRule = !_vm.makeRule
                    }
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "row row--align-center mt-25" },
          [
            _c(
              "base-button",
              {
                staticClass: "bg-blue fc-white mr-6",
                on: {
                  click: _vm.submitMerge,
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.submitMerge($event)
                  }
                }
              },
              [_vm._v("\n        Merge Debtors\n      ")]
            ),
            _c(
              "base-button",
              {
                staticClass: "bg-light fc-white mr-6",
                on: {
                  click: _vm.cancel,
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.cancel($event)
                  }
                }
              },
              [_vm._v("\n        Cancel\n      ")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row row--justify-between" }, [
      _c("label", { staticClass: "MergeDebtor__heading fs-18 fw-med mb-13" }, [
        _vm._v("\n      Select debtor to merge\n    ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }