<template>
  <div class="MergeDebtor">
    <div class="row row--justify-between">
      <label class="MergeDebtor__heading fs-18 fw-med mb-13">
        Select debtor to merge
      </label>
    </div>

    <!-- EXTERNAL VALIDATION -->
    <div
      v-if="validationInstructions"
      class="mb-12"
    >
      <label class="fc-red fs-12 uppercase">
        {{ validationInstructions }}
      </label>
    </div>


    <div class="column">
      <base-debtor-search
        @selected="setDebtorID"
        class="mb-14"
        :debtor-i-d-from-query="debtorID"
        :label="true"
        :label-for="'Debtor'"
      >
        <template v-slot:label>
          DEBTOR
        </template>
      </base-debtor-search>

      <base-checkbox
        v-model="makeRule"
        class="mb-2"
        :label-for="'require-user'"
        :starting-value="false"
      >
        Make Rule?
      </base-checkbox>

      <transition name="Transition__fade">
        <base-rule-create
          v-if="makeRule"
          @close-create-rule="makeRule = !makeRule"
        />
      </transition>

      <!-- BUTTONS -->
      <div class="row row--align-center mt-25">
        <base-button
          @click="submitMerge"
          @keydown.enter="submitMerge"
          class="bg-blue fc-white mr-6"
        >
          Merge Debtors
        </base-button>
        <base-button
          @click="cancel"
          @keydown.enter="cancel"
          class="bg-light fc-white mr-6"
        >
          Cancel
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import BaseButton from './base-button.vue'
import BaseCheckbox from './base-checkbox.vue'
import BaseDebtorSearch from './base-debtor-search.vue'
import BaseRuleCreate from './base-rule-create.vue'
// Helpers
import { Debtor } from '../utils/api'

export default {
  name: 'MergeDebtor',

  components: {
    BaseButton,
    BaseCheckbox,
    BaseDebtorSearch,
    BaseRuleCreate,
  },

  props: {
    debtor: {
      type: Object,
      required: true,
    },
  },

  data () {
    return {
      debtorID: null,
      duplicateDebtor: null,
      makeRule: false,
      validationInstructions: null,
    }
  },

  methods: {
    cancel () {
      this.$emit('close-merge')
    },

    setDebtorID (filter) {
      if (!filter) {
        this.debtorID = null
        this.duplicateDebtor = null
        return
      }
      this.duplicateDebtor = filter
      this.debtorID = filter.id
    },

    async submitMerge () {
      if (!this.validation()) return
      this.progressStart()

      try {
        // merge the debtors
        await Debtor.mergeDebtors({
          duplicate: this.duplicateDebtor,
          original: this.debtor,
        })
        this.requestSuccess({ message: 'Merge successfully completed' })
        this.$emit('close-merge')
      }
      catch (error) {
        this.captureSentryEvent(
          'Merge Debtor "Submit" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue creating the rule' })
      }
    },

    validation () {
      let valid = true
      this.validationInstructions = null

      if (!this.duplicateDebtor) {
        valid = false
        this.validationInstructions = 'You must select a debtor to merge'
      }

      if (this.debtorID === this.debtor.id) {
        valid = false
        this.validationInstructions = 'You cannot merge a debtor with itself'
      }

      return valid
    },
  },
}
</script>